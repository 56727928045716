// CSS Imports
import '/src/css/style.css';

// NPM Imports
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';

window.Alpine = Alpine;
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// if (import.meta.hot) {
//   import.meta.hot.accept(() => {
//     console.log("HMR");
//   });
// }
